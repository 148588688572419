import { deleteDB, openDB } from 'idb';
import moment from 'moment';
import {
    type Authorization,
    type BrakeCode,
    type Calendar,
    type CategoryLine,
    type Crossing,
    type Device,
    type DownloadingOfflineData,
    type DutyTour,
    type DutyTourTrain,
    type Hour,
    type Line,
    type LineRouteboardColumn,
    type LineSection,
    type LocalDBData,
    type LocalDbInfo,
    type Path,
    type Remark,
    type Role,
    type RoleAuthorization,
    type RoleSetting,
    type RouteboardColumn,
    type Schedule,
    type Section,
    type Setting,
    type Sign,
    type SignAttachment,
    type Speed,
    type Step,
    type Tracking,
    type Train,
    type User
} from '../types';


const createLocalDb = async function(version: number, datas: LocalDBData["data"], setDownloadingOfflineData: React.Dispatch<DownloadingOfflineData | null>) {
    
    const tables: {name: keyof LocalDBData["data"], indexes: (string | string[])[]}[] = [
        {
            name: 'routeboardColumns',
            indexes: []
        },
        {
            name: 'trains',
            indexes: []
        },
        {
            name: 'lines',
            indexes: []
        },
        {
            name: 'lineRouteboardColumns',
            indexes: ['line_id', 'routeboard_column_id']
        },
        {
            name: 'lineSections',
            indexes: ['line_id', 'section_id']
        },
        {
            name: 'sections',
            indexes: []
        },
        {
            name: 'steps',
            indexes: ['section_id']
        },
        {
            name: 'pathes',
            indexes: ['step_id']
        },
        {
            name: 'schedules',
            indexes: ['train_id', 'calendar_id']
        },
        {
            name: 'hours',
            indexes: ['step_id', 'schedule_id', ['schedule_id', 'step_id'], ['schedule_id', 'line_section_id']]
        },
        {
            name: 'signs',
            indexes: []
        },
        {
            name: 'signsAttachments',
            indexes: ['sign_id', 'step_id', 'path_id', 'hour_id']
        },
        {
            name: 'remarks',
            indexes: ['step_id', 'path_id', 'hour_id']
        },
        {
            name: 'calendars',
            indexes: []
        },
        {
            name: 'dutyTours',
            indexes: []
        },
        {
            name: 'dutyTourTrains',
            indexes: ['duty_tour_id']
        },
        {
            name: 'categories',
            indexes: []
        },
        {
            name: 'categoryLines',
            indexes: ['category_id', 'line_id']
        },
        {
            name: 'brakeCodes',
            indexes: []
        },
        {
            name: 'speeds',
            indexes: ['step_id']
        },
        {
            name: 'crossings',
            indexes: ['hour_id']
        },
        {
            name: 'users',
            indexes: []
        },
        {
            name: 'devices',
            indexes: []
        },
        {
            name: 'deviceRoles',
            indexes: ['device_id']
        },
        {
            name: 'deviceRegistrations',
            indexes: ['device_id']
        },
        {
            name: 'roles',
            indexes: []
        },
        {
            name: 'roleAuthorizations',
            indexes: ['role_id']
        },
        {
            name: 'authorizations',
            indexes: []
        },
        {
            name: 'roleSettings',
            indexes: [['role_id', 'setting_id']]
        },
        {
            name: 'settings',
            indexes: []
        },
        {
            name: 'userDutyTours',
            indexes: ['user_id']
        },
        {
            name: 'trackings',
            indexes: ['schedule_id', 'user_id']
        }
    ];

    const currentRawDB = localStorage.getItem('mecadrive_offline');
    const currentDB: LocalDbInfo | null = currentRawDB ? JSON.parse(currentRawDB) : null;
    
    if (currentDB !== null && currentDB.db_version !== null && currentDB.db_version !== version) {
        await deleteDB('mecadrive_offline');
    }

    setDownloadingOfflineData({
        downloading: false,
        total_tables: tables.length,
        processed_tables: 0
    });

    const db = await openDB('mecadrive_offline', version, {
        upgrade(db) {
            let tableStore;
            
            for (const table of tables) {
            
                if (db.objectStoreNames.length && db.objectStoreNames.contains(table.name)) {
                    db.deleteObjectStore(table.name);
                }
                
                tableStore = db.createObjectStore(table.name, {keyPath: 'id', autoIncrement: true});
                for (const index of table.indexes) {
                    if (Array.isArray(index)) {
                        tableStore.createIndex(index.join('_'), index);
                    }
                    else {
                        tableStore.createIndex(index, index);
                    }
                    
                }
                
            }
            
        },
        /*blocked() {
            alert("Une erreur s'est produite. Veuillez recharger l'application et vérifier que les données sont à jour. En cas de doutes, supprimer les données à la page « Mon compte », puis rechargez-les.");
        }*/
    });

    for (const [tableIndex, table] of tables.entries()) {
        if (table.name in datas) {
            setDownloadingOfflineData({
                downloading: false,
                total_tables: tables.length,
                processed_tables: tableIndex
            });

            if (datas[table.name].length) {
                const tx = db.transaction(table.name, 'readwrite');
                await Promise.all([datas[table.name].forEach(data => tx.store.add(data)), tx.done]);
            }

            /*for (const data of datas[table.name]) {
                await db.add(table.name, data);
            }*/
            
        }
    }

    db.close();
    
};

const getLocalDashboard = async function(
    user: User | null, 
    date: string,
    setTrains: React.Dispatch<Train[]>, 
    setSchedules: React.Dispatch<Schedule[]>, 
    setLines: React.Dispatch<Line[]>, 
    setSections: React.Dispatch<Section[]>, 
    setCalendars: React.Dispatch<Calendar[]>, 
    setDutyTours: React.Dispatch<DutyTour[]>, 
    setLoading: React.Dispatch<boolean>
) {
    
    let datas: {
        trains: Train[],
        schedules: Schedule[],
        lines: Line[],
        sections: Section[],
        calendars: Calendar[],
        dutyTours: DutyTour[]
    } = {
        trains: [],
        schedules: [],
        lines: [],
        sections: [],
        calendars: [],
        dutyTours: []
    };
    
    const db = await openDB<LocalDBData["data"]>('mecadrive_offline');

    let linesAllowed: number[] = [];

    const categoryLines: CategoryLine[] = user?.category_id ? await db.getAllFromIndex('categoryLines', 'category_id', user.category_id) : []; 
    linesAllowed = linesAllowed.concat(categoryLines.map(categoryLine => categoryLine.line_id));

    datas.trains = [];
    
    let cursor = await db.transaction('calendars').store.openCursor();
    while (cursor) {
        const calendar: Calendar = cursor.value;
        if (moment(date).isBetween(moment(calendar.date_from), moment(calendar.date_to), null, '[]')) {
            const dayBitIndex = (moment(date).diff(moment(calendar.date_from), 'days'));
            if (dayBitIndex >= 0 && calendar.day_bits[dayBitIndex] === '1') {
                datas.calendars.push(calendar);
            }
        }
        cursor = await cursor.continue();
    }
    
    for (const calendar of datas.calendars) {
        const schedules: Schedule[] = await db.getAllFromIndex('schedules', 'calendar_id', calendar.id);
        for (const schedule of schedules) {
            if (schedule.enabled && moment(date).isBetween(moment(schedule.date_from), moment(schedule.date_to), null, '[]')) {
                const train: Train = await db.get('trains', schedule.train_id);

                if (linesAllowed.length && !linesAllowed.includes(train.line_id)) {
                    continue;
                }
                
                schedule.hours = await db.getAllFromIndex('hours', 'schedule_id', schedule.id);
                datas.schedules.push(schedule);
                datas.trains.push(train);
            }
        }
    }

    /*const days = await db.getAllFromIndex('days', 'date', date);
    for (const day of days) {
        const scheduleDayTypes: ScheduleDayType[] = await db.getAllFromIndex('scheduleDayTypes', 'day_type_id', day.day_type_id);
        for (const scheduleDayType of scheduleDayTypes) {
            const schedule = await db.get('schedules', scheduleDayType.schedule_id);
            const train = await db.get('trains', schedule.train_id);
            if (linesAllowed.length && !linesAllowed.includes(train.line_id)) {
                continue;
            }
            if (schedule.enabled) {
                datas.trains.push(train);
                schedule.hours = await db.getAllFromIndex('hours', 'schedule_id', schedule.id);
                schedule.day_types_id = (await db.getAllFromIndex('scheduleDayTypes', 'schedule_id', schedule.id)).map(scheduleDayType => scheduleDayType.day_type_id);

                schedules.push(schedule);
            }
        }
    }
    datas.schedules = schedules;*/

    let lines: Line[] = [];
    if (!linesAllowed.length) {
        lines = await db.getAll('lines');
    }
    else {
        for (const line_id of linesAllowed) {
            const line = await db.get('lines', line_id);
            lines.push(line);
        }
    }
    
    for (const line of lines) {
        line.line_sections = await db.getAllFromIndex('lineSections', 'line_id', line.id);

        datas.lines.push(line);
    }

    const sections: Section[] = await db.getAll('sections');
    
    for (const section of sections) {
        let steps: Step[] = await db.getAllFromIndex('steps', 'section_id', section.id);
        section.steps = [];

        for (const step of steps) {
            step.pathes = [];
            const pathes: Path[] = await db.getAllFromIndex('pathes', 'step_id', step.id);
            for (const path of pathes) {

                path.sign_attachments = [];
                const signsAttachments: SignAttachment[] = await db.getAllFromIndex('signsAttachments', 'path_id', path.id);
                for (const signAttachment of signsAttachments) {
                    const sign: Sign = await db.get('signs', signAttachment.sign_id);
                    path.sign_attachments.push({...signAttachment, sign: sign});
                }

                step.pathes.push(path);
            }

            step.sign_attachments = [];
            const signsAttachments: SignAttachment[] = await db.getAllFromIndex('signsAttachments', 'step_id', step.id);
            for (const signAttachment of signsAttachments) {
                const sign: Sign = await db.get('signs', signAttachment.sign_id);
                step.sign_attachments.push({...signAttachment, sign: sign});
            }

            step.remarks = [];
            const remarks: Remark[] = await db.getAllFromIndex('remarks', 'step_id', step.id);
            for (const remark of remarks) {
                step.remarks.push(remark);
            }

            step.speeds = [];
            const speeds: Speed[] = await db.getAllFromIndex('speeds', 'step_id', step.id);
            
            for (const speed of speeds) {
                step.speeds.push(speed);
            }


            section.steps.push(step);
        }

        datas.sections.push(section);
    }

    const dutyTours: DutyTour[] = await db.getAll('dutyTours');
    for (const dutyTour of dutyTours) {
        
        if (dutyTour.enabled) {
            dutyTour.trains = [];
            
            const dutyTourTrains: DutyTourTrain[] = await db.getAllFromIndex('dutyTourTrains', 'duty_tour_id', dutyTour.id);
            for (const dutyTourTrain of dutyTourTrains) {
                
                const schedule: Schedule = await db.get('schedules', dutyTourTrain.schedule_id);
                if (schedule) {
                    const train: Train = await db.get('trains', schedule.train_id);
                
                    if (train.line_id && linesAllowed.length && !linesAllowed.includes(train.line_id)) {
                        continue;
                    }
                    dutyTour.trains.push(dutyTourTrain);
                }
            }

            datas.dutyTours.push(dutyTour);
        }
    }
    
    setTrains(datas.trains);
    setSchedules(datas.schedules);
    setLines(datas.lines);
    setSections(datas.sections);
    setCalendars(datas.calendars);
    setDutyTours(datas.dutyTours);
    setLoading(false);
    
    db.close();
};

const getLocalRouteboard = async function(
    version: number, 
    schedule_id: number, 
    sections: Section[], 
    setRouteboardColumns: React.Dispatch<RouteboardColumn[]>, 
    setSchedule: React.Dispatch<Schedule>, 
    setLine: React.Dispatch<Line>, 
    setBrakeCodes: React.Dispatch<BrakeCode[]>, 
    setLoading: React.Dispatch<boolean>
) {
    
    let datas: {
        routeboardColumns: RouteboardColumn[],
        schedule: Schedule | null,
        line: Line | null,
        brakeCodes: BrakeCode[]
    } = {
        routeboardColumns: [],
        schedule: null,
        line: null,
        brakeCodes: []
    };

    const db = await openDB('mecadrive_offline', version);

    datas.routeboardColumns = await db.getAll('routeboardColumns');

    const schedule: Schedule = await db.get('schedules', schedule_id);
    if (schedule) {
        const train: Train = await db.get('trains', schedule.train_id);
        //train.schedule = schedule;
        datas.schedule = schedule;
    
        if (train.line_id) {

            const line: Line = await db.get('lines', train.line_id);
            
            const lineRouteboardColumns: LineRouteboardColumn[] = await db.getAllFromIndex('lineRouteboardColumns', 'line_id', line.id);
            line.routeboard_columns_id = lineRouteboardColumns.map(lineRouteboardColumn => lineRouteboardColumn.routeboard_column_id);

            const lineSections: LineSection[] = await db.getAllFromIndex('lineSections', 'line_id', line.id);
            line.line_sections = [];
            
            for (const lineSection of lineSections) {
                const hours: Hour[] = await db.getAllFromIndex('hours', 'schedule_id_line_section_id', [schedule_id, lineSection.id]);
                lineSection.hours = [];

                for (const hour of hours) {
                    hour.crossings = await db.getAllFromIndex('crossings', 'hour_id', hour.id);
                    hour.sign_attachments = [];
                    hour.remarks = [];

                    if (line.automatic_crossings) {
                        const automatic_crossings: Crossing[] = [];
                        const crossing_hours: Hour[] = await db.getAllFromIndex('hours', 'step_id', hour.step_id);

                        for (const crossing_hour of crossing_hours) {
                            

                            /*if (
                                (hour.arrival && (hour.arrival === crossing_hour.arrival || hour.arrival === crossing_hour.departure)) || 
                                (hour.departure && (hour.departure === crossing_hour.arrival || hour.departure === crossing_hour.departure)) || 
                                (hour.arrival && hour.departure && crossing_hour.arrival && crossing_hour.departure && (
                                    (hour.arrival > crossing_hour.arrival && hour.arrival < crossing_hour.departure) || (hour.departure > crossing_hour.arrival && hour.departure < crossing_hour.departure)
                                ))
                            ) {*/
                            if (
                                (hour.arrival && (hour.arrival === crossing_hour.arrival || hour.arrival === crossing_hour.departure)) || 
                                (hour.departure && (hour.departure === crossing_hour.arrival || hour.departure === crossing_hour.departure)) || 
                                (crossing_hour.arrival && crossing_hour.departure && (
                                    (hour.arrival && hour.arrival > crossing_hour.arrival && hour.arrival < crossing_hour.departure) || (hour.departure && hour.departure > crossing_hour.arrival && hour.departure < crossing_hour.departure)
                                )) || 
                                (hour.arrival && hour.departure && (
                                    (crossing_hour.arrival && crossing_hour.arrival > hour.arrival && crossing_hour.arrival < hour.departure) || (crossing_hour.departure && crossing_hour.departure > hour.arrival && crossing_hour.departure < hour.departure)
                                ))
                            ) {
                                const crossing_schedule: Schedule = await db.get('schedules', crossing_hour.schedule_id);
                                if (crossing_schedule.train_id === train.id) {
                                    continue;
                                }
                                
                                const crossing_line_section: LineSection = await db.get('lineSections', crossing_hour.line_section_id);
                                automatic_crossings.push({
                                    id: 0,
                                    hour_id: hour.id,
                                    hour_crossing_id: crossing_hour.id,
                                    line_section_crossing_id: crossing_hour.line_section_id,
                                    path_crossing_id: crossing_hour.path_id,
                                    schedule_crossing_id: crossing_hour.schedule_id,
                                    train_crossing_id: crossing_schedule.train_id,
                                    line_crossing_id: crossing_line_section.line_id,
                                    section_crossing_id: crossing_line_section.section_id
                                });
                                continue;
                            }
                        }
                        
                        hour.crossings = [...hour.crossings, ...automatic_crossings];
                        
                    }
                    
                    const signsAttachments: SignAttachment[] = await db.getAllFromIndex('signsAttachments', 'hour_id', hour.id);
                    for (const signAttachment of signsAttachments) {
                        const sign: Sign = await db.get('signs', signAttachment.sign_id);
                        //hour.sign_attachments.push({...signAttachment, rank: sign.rank, icon: sign.icon, position: parseInt(sign.position), label_style: sign.label_style});
                        hour.sign_attachments.push({...signAttachment, sign: sign});
                    }

                    const remarks: Remark[] = await db.getAllFromIndex('remarks', 'hour_id', hour.id);
                    for (const remark of remarks) {
                        hour.remarks.push(remark);
                    }

                    if (hour.path_id) {
                        const path: Path = await db.get('pathes', hour.path_id);
                        //hour.path_id = path;

                        /*const signsAttachments = await db.getAllFromIndex('signsAttachments', 'path_id', path.id);
                        for (const signAttachment of signsAttachments) {
                            const sign = await db.get('signs', signAttachment.sign_id);
                            hour.signs.push({...signAttachment, rank: sign.rank, icon: sign.icon, position: parseInt(sign.position), label_style: sign.label_style});
                        }

                        const remarks = await db.getAllFromIndex('remarks', 'path_id', path.id);
                        for (const remark of remarks) {
                            hour.remarks.push(remark);
                        }*/
                    }

                    lineSection.hours.push(hour);
                }

                line.line_sections.push(lineSection);
            }

            datas.line = line;
            
            for (const line_section of line.line_sections) {
                const section = sections[sections.findIndex(section => section.id === line_section.section_id)];
                if (section.steps) {
                    for (const step of section.steps) {
                        if (step.speeds) {
                            for (const speed of step.speeds) {
                                const brake_code: BrakeCode = await db.get('brakeCodes', speed.brake_code_id);
                                if (datas.brakeCodes.findIndex(brakeCode => brakeCode.id === brake_code.id) === -1) {
                                    datas.brakeCodes.push(brake_code);
                                }
                            }
                        }
                    }
                }
                
            }
            
            setRouteboardColumns(datas.routeboardColumns);
            if (datas.schedule) {
                setSchedule(datas.schedule);
            }
            if (datas.line) {
                setLine(datas.line);
            }
            setBrakeCodes(datas.brakeCodes);
        }
    }
    
    setLoading(false);

    db.close();
};

/*const getLocalCrossings = async function(hour_id: number) {

    const db = await openDB('mecadrive_offline');
    
    const crossings = await db.get('crossings', hour_id);

    db.close();

    return crossings ? crossings : null;
};*/

const getLocalTrackings = async function() {
    const db = await openDB('mecadrive_offline');
    const trackings = await db.getAll('trackings');
    
    return trackings;
};

const getLocalUnsyncTrackings = async function() {
    const db = await openDB('mecadrive_offline');
    const trackings: Tracking[] = [];
    let cursor = await db.transaction('trackings').store.openCursor();

    while (cursor) {
        if (!cursor.value.sync) {
            trackings.push(cursor.value);
        }
        cursor = await cursor.continue();
    }
    
    return trackings;
};

const setLocalTrackings = async function(tracking: Tracking) {
    const db = await openDB('mecadrive_offline');
    return await db.put('trackings', tracking);
};

const insertLocalTracking = async function(tracking: Tracking) {
    const db = await openDB('mecadrive_offline');
    await db.put('trackings', tracking);
    return true;
};

const getLocalUser = async function(user_id: number) : Promise<User | null> {
    const db = await openDB<LocalDBData["data"]>('mecadrive_offline');
    if (db.objectStoreNames.contains('users')) {
        return await db.get('users', user_id);
    }
    return null;
};

const getLocalRole = async function(role_id: number) : Promise<Role | null> {
    const db = await openDB<LocalDBData["data"]>('mecadrive_offline');
    let role: Role | null = null;
    if (db.objectStoreNames.contains('roles')) {
        role = await db.get('roles', role_id);
        if (role) {
            const roleAuthorizations: RoleAuthorization[] = await db.getAllFromIndex('roleAuthorizations', 'role_id', role_id);
            for (const roleAuthorization of roleAuthorizations) {
                const authorization: Authorization  = await db.get('authorizations', roleAuthorization.authorization_id);
                role.authorizations[authorization.identifier] = roleAuthorization.authorization;
            }

            const settings: Setting[] = await db.getAll('settings');
            for (const setting of settings) {
                const roleSetting: RoleSetting | undefined = await db.getFromIndex('roleSettings', 'role_id_setting_id', [role_id, setting.id]);
                if (roleSetting) {
                    role.settings[setting.identifier] = {'locked': roleSetting.locked, 'value': roleSetting.value};
                }
                else {
                    role.settings[setting.identifier] = {'locked': false, 'value': ''};
                }
            }
        }
    }

    return role;
};

const getLocalDevice = async function(device_id: number) : Promise<Device | null> {
    const db = await openDB<LocalDBData["data"]>('mecadrive_offline');
    let device: Device | null = null;
    if (db.objectStoreNames.contains('devices')) {
        device = await db.get('devices', device_id);
        if (device) {
            device.registration = await db.getFromIndex('deviceRegistrations', 'device_id', device_id);
        }
    }

    return device;
};

export {
    createLocalDb, getLocalDashboard, getLocalDevice, getLocalRole, getLocalRouteboard, getLocalTrackings, getLocalUnsyncTrackings, getLocalUser, insertLocalTracking, setLocalTrackings
};

